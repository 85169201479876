import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalService } from '@azure/msal-angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsModule } from '@ngxs/store';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { from, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core';
import { LayoutModule, MaterialModule } from './layout';
import { RedirectComponent } from './redirect.component';
import { RouterStateSerializer, withNgxsRouterPlugin } from '@ngxs/router-plugin';
import { CustomRouterStateSerializer } from './custom-router-state-serializer';

export class LazyTranslateLoader implements TranslateLoader {
    public getTranslation(lang: string): Observable<unknown> {
        return from(import(`../assets/i18n/${lang}.json`)).pipe(catchError(() => of({})));
    }
}

function initializeAppFactory(msal: MsalService): () => Observable<void> {
    return () => msal.initialize();
}

@NgModule({
    bootstrap: [AppComponent],
    declarations: [AppComponent, RedirectComponent],
    imports: [
        BrowserModule,
        CommonModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        CoreModule.forRoot(),
        LayoutModule,
        NgxsModule.forRoot([]),
        NgxsLoggerPluginModule.forRoot(),
        NgxsReduxDevtoolsPluginModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: LazyTranslateLoader,
            },
        }),
        MaterialModule.forRoot(),
    ],
    providers: [
        provideEnvironmentNgxMask(),
        provideHttpClient(withInterceptorsFromDi()),
        withNgxsRouterPlugin(),
        { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
        [
            {
                deps: [MsalService],
                multi: true,
                provide: APP_INITIALIZER,
                useFactory: initializeAppFactory,
            },
        ],
    ],
})
export class AppModule {}
